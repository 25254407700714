import { useCallback, useMemo, type ReactNode } from "react";
import { type ExtraLink, type ExtraButton, type ButtonProps } from "./index";

// Menu Items with Headless UI
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

// NextJS
import Link from "next/link";

interface DropdownMenuProps extends ButtonProps {
  children: ReactNode;
}

const DropdownMenu = ({
  children,
  onClick,
  href,
  extraLinks,
}: DropdownMenuProps) => {
  const isButton = useMemo(() => {
    // If we have an onClick prop, we're a button
    if (onClick) {
      return true;
    }

    return false;
  }, [onClick, href]);

  const onButtonClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  return (
    <div className="inline-flex justify-center rounded-md bg-white text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-50">
      {isButton ? (
        <button
          className="bg-white hover:bg-gray-100 grow inline-flex gap-x-2 items-center p-2 pl-3 pr-4 rounded-s-md ring-1 ring-gray-300 ring-inset -mr-1"
          onClick={onButtonClick}
        >
          {children}
        </button>
      ) : (
        <Link
          href={href || "#"}
          className="bg-white hover:bg-gray-100 grow inline-flex gap-x-2 items-center p-2 pl-3 pr-4 rounded-s-md ring-1 ring-gray-300 ring-inset -mr-1"
        >
          {children}
        </Link>
      )}

      <Menu as="div" className="relative inline-block text-left">
        <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-e-md bg-white p-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 ring-1 ring-gray-300 ring-inset">
          <ChevronDownIcon
            aria-hidden="true"
            className="size-5 text-gray-400"
          />
        </MenuButton>

        <MenuItems
          transition
          className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
        >
          <div className="py-1">
            {extraLinks &&
              extraLinks.map((link: ExtraLink | ExtraButton, index) => (
                <DropdownMenuItem key={`${link.title}-${index}`} {...link} />
              ))}
          </div>
        </MenuItems>
      </Menu>
    </div>
  );
};

const DropdownMenuItem = (props: ExtraLink | ExtraButton) => {
  const { title } = props;

  const isLink = (props: ExtraLink | ExtraButton): props is ExtraLink => {
    return (props as ExtraLink).href !== undefined;
  };

  return (
    <MenuItem>
      {isLink(props) ? (
        <a
          href={props.href}
          target={props.target}
          className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
        >
          {title}
        </a>
      ) : (
        <button
          onClick={props.onClick || undefined}
          className="block w-full text-left px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
        >
          {title}
        </button>
      )}
    </MenuItem>
  );
};

export default DropdownMenu;
