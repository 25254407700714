"use client";

import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Label,
} from "@headlessui/react";
import { useEffect, useMemo, useState } from "react";

import { Spinner } from "@/components/Buttons";
import { ChevronUpDownIcon } from "@heroicons/react/16/solid";
import { CheckIcon } from "@heroicons/react/20/solid";

// Fuzzy search for the address
import Fuse from "fuse.js";

export default function AddressComboBox({
  options,
  label,
  value,
  loading,
  setValue,
  onSelect,
}: {
  label: string;
  loading: boolean;
  options: { label: string; value: string | number }[];
  value: string;
  setValue: (value: string) => void;
  onSelect: (index: string | number) => void;
}) {
  const [selected, setSelected] = useState<string>();

  // When the user selects an address, call the onSelect function
  useEffect(() => {
    if (!selected) {
      return;
    }

    onSelect(selected);
  }, [selected]);

  let fuse = useMemo(() => {
    let fuse = new Fuse(options, {
      keys: ["label"],
      threshold: 0.3,
    });
    return fuse;
  }, [options]);

  let results = useMemo(() => {
    if (value.trim().length === 0) {
      return options;
    }

    let results = fuse.search(value);

    // If we have no results, return the options as is
    if (results.length === 0) {
      return options;
    }

    return results.map((r) => r.item);
  }, [fuse, options, value]);

  return (
    <Combobox
      as="div"
      value={value}
      onChange={(value) => {
        // Set the selected index convert to a interger
        if (value) {
          setSelected(value);
        }
      }}
    >
      <Label className="block text-sm/6 font-medium text-gray-900">
        {label}
      </Label>
      <div className="mt-1 relative">
        <div className="flex rounded-md shadow-sm ring-1 ring-gray-300 bg-white ring-inset focus-within:ring-2 focus-within:ring-martEye-400">
          <ComboboxInput
            placeholder="Start typing your address to get suggestions"
            className="block min-h-[40px] w-full rounded-md py-1.5 pl-3 pr-12 text-base text-gray-900 order border-0 bg-transparent focus:outline-none focus:border-none focus:shadow-none focus:ring-0 sm:text-sm/6"
            onChange={(event) => setValue(event.target.value)}
          />

          <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            {!!options.length && (
              <ChevronUpDownIcon
                className="size-5 text-gray-400"
                aria-hidden="true"
              />
            )}

            {loading && <Spinner className="w-4 h-4" />}
          </ComboboxButton>
        </div>

        {results.length > 0 && (
          <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
            {results.map((option, index) => (
              <ComboboxOption
                key={option.value}
                value={option.value}
                className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-martEye-400 data-[focus]:text-white data-[focus]:outline-none"
              >
                <span className="block truncate group-data-[selected]:font-semibold">
                  {option.label}
                </span>

                <span className="absolute inset-y-0 right-0 hidden items-center pr-4 text-martEye-400 group-data-[selected]:flex group-data-[focus]:text-white">
                  <CheckIcon className="size-5" aria-hidden="true" />
                </span>
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        )}
      </div>
    </Combobox>
  );
}
