"use client";

import { type PostcoderAddress } from "@/pages/api/[marketId]/addressLookup";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { convertPostcoderAddress } from "./helpers";
import { type PostCoderFieldProps } from "./index";

// UI
import { toast } from "sonner";
import AddressComboBox from "./combobox";

const DEBOUNCE_DELAY = 300;

const AddressLookupField = ({
  zip,
  country,
  marketId,
  address1,
  onChange,
  onSelect,
}: PostCoderFieldProps) => {
  const [debouncedValues, setDebouncedValues] = useState({
    zip,
    country,
    marketId,
  });

  const [isFetching, setIsFetching] = useState(false);
  const [isValidZip, setIsValidZip] = useState(false);
  const [isValidCountry, setIsValidCountry] = useState(false);
  const [addresses, setAddresses] = useState<PostcoderAddress[]>([]);

  // Store the last successful request to skip identical re-fetches
  const lastRequestRef = useRef({
    zip: "",
    country: "",
    marketId: "",
  });

  // 1. Debounce zip/country/marketId changes before validating/fetching
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValues({ zip, country, marketId });
    }, DEBOUNCE_DELAY);

    return () => {
      clearTimeout(handler);
    };
  }, [zip, country, marketId]);

  // 2. Validate the debounced country
  useEffect(() => {
    const shortCountry = debouncedValues.country.substring(0, 2).toUpperCase();

    if (shortCountry !== "GB" && shortCountry !== "IE") {
      setIsValidCountry(false);
      setAddresses([]);
      return;
    }
    setIsValidCountry(true);
  }, [debouncedValues.country]);

  // 3. Validate the debounced zip
  useEffect(() => {
    const currentZip = debouncedValues.zip;
    let zip = currentZip.replace(/\s+/g, "");
    if (zip.length < 5 || zip.length > 10) {
      setIsValidZip(false);
      setAddresses([]);
      return;
    }
    setIsValidZip(true);
  }, [debouncedValues.zip]);

  // 4. If valid, try to fetch addresses
  useEffect(() => {
    // Bail out if not valid or missing marketId
    if (!isValidZip || !isValidCountry || !debouncedValues.marketId) {
      return;
    }

    // Check if we are already fetching
    if (isFetching) {
      return;
    }

    // Shorten the country to two characters
    const shortCountry = debouncedValues.country.substring(0, 2).toUpperCase();

    // Check if we're requesting the exact same thing
    if (
      lastRequestRef.current.zip === debouncedValues.zip &&
      lastRequestRef.current.country === shortCountry &&
      lastRequestRef.current.marketId === debouncedValues.marketId
    ) {
      // No need to re-fetch if it's the same request
      return;
    }

    // Update the last request ref so we don't repeat fetch
    lastRequestRef.current = {
      zip: debouncedValues.zip,
      country: shortCountry,
      marketId: debouncedValues.marketId,
    };

    // Now fetch addresses
    setIsFetching(true);

    fetch(`/api/${debouncedValues.marketId}/addressLookup`, {
      method: "POST",
      body: JSON.stringify({
        zip: debouncedValues.zip,
        country: shortCountry,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`Failed to fetch addresses: ${response.statusText}`);
        }
        return await response.json();
      })
      .then((data) => {
        setAddresses(data);
      })
      .catch((e) => {
        // Add a error toast
        toast.error("Failed to fetch addresses");

        console.error("Error occurred:", e.message);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [isValidZip, isValidCountry, debouncedValues, isFetching]);

  const onSelectAddress = useCallback(
    (address: PostcoderAddress) => {
      const convertedAddress = convertPostcoderAddress(address);
      onSelect(convertedAddress);
    },
    [onSelect]
  );

  const addressOptions = useMemo(() => {
    if (!addresses) {
      return [];
    }

    return addresses.map((address, index) => {
      return {
        label: address.summaryline,
        value: index,
      };
    });
  }, [addresses]);

  return (
    <>
      <AddressComboBox
        label="Address 1"
        options={addressOptions}
        onSelect={(index) => {
          const address = addresses[index as number];
          if (address) {
            onSelectAddress(address);
          }
        }}
        loading={isFetching}
        value={address1}
        setValue={onChange}
      />
    </>
  );
};

export default memo(AddressLookupField);
