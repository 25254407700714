import { genColour, getProductCodeColorThemeFromList } from "@/data/colours";
import { useStudioStream } from "@/data/studio-react/useStudioStream";
import Link from "next/link";
import { useRouter } from "next/router";
import { PropsWithChildren, Ref } from "react";
import { classNames } from "../../data/classnames";

// DropdownMenu
import DropdownMenu from "./dropdownButton";

export const groupColours = genColour;

export interface ButtonProps {
  href?: string;
  tabIndex?: number;
  onClick?: (() => void) | null;
  isDisabled?: boolean;
  iconPosition?: string;
  isLoading?: boolean;
  title?: string;
  icon?: React.ReactNode | any;
  className?: string;
  type?: "button" | "submit" | "reset" | undefined;
  target?: "_blank" | "_self" | "_parent" | "_top";
  variant?: "primary" | "primary-light" | "secondary" | "tertiary" | "danger";
  ref?: Ref<HTMLButtonElement>;
  extraLinks?: (ExtraButton | ExtraLink)[];
}

export interface ExtraLink {
  href: string;
  title: string;
  target?: "_blank" | "_self" | "_parent" | "_top";
}

export interface ExtraButton {
  onClick: (() => void) | null;
  title: string;
}

export function Button(props: PropsWithChildren<ButtonProps>) {
  let {
    href,
    icon,
    title,
    onClick,
    children,
    isLoading,
    tabIndex = 0,
    isDisabled,
    iconPosition = "left",
    type = "button",
    className,
    variant = "primary",
    target = "_self",
    extraLinks,
    ref = null,
  } = props;

  // If we have extra links then we are actually rendering a dropdown with a button and links
  if (extraLinks && extraLinks.length > 0) {
    let buttonProps = props;
    return (
      <DropdownMenu {...buttonProps}>
        {icon}
        {children}
      </DropdownMenu>
    );
  }

  if (href) {
    return (
      <Link
        title={title}
        href={href}
        target={target}
        className={classNames(
          "inline-flex items-center gap-x-1.5 rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2",
          variant === "primary" && !isDisabled
            ? "bg-martEye-400 text-white focus:ring-martEye-400 hover:bg-martEye-700"
            : "",
          variant === "primary" && isDisabled
            ? "cursor-not-allowed bg-grey-300 text-white focus:ring-grey-400 hover:bg-grey-400"
            : "",
          variant === "primary-light" && !isDisabled
            ? "bg-martEye-100 text-martEye-400 focus:ring-martEye-400 hover:bg-martEye-400 hover:text-white"
            : "",
          variant === "primary-light" && isDisabled
            ? "cursor-not-allowed bg-grey-300 text-white focus:ring-grey-400 hover:bg-grey-400"
            : "",
          variant === "secondary" && !isDisabled
            ? "bg-white text-martEye-400 focus:ring-martEye-400 hover:bg-martEye-700 hover:text-white "
            : "",
          variant === "secondary" && isDisabled
            ? "cursor-not-allowed bg-grey-300 text-white focus:ring-grey-400 hover:bg-grey-400"
            : "",
          variant === "tertiary" && !isDisabled
            ? "!border-gray-300  bg-white shadow-sm focus:ring-martEye-400 hover:bg-gray-100"
            : "",
          variant === "tertiary" && isDisabled
            ? "cursor-not-allowed !border-gray-300 bg-transparent !text-gray-500 focus:ring-martEye-400 hover:bg-gray-100"
            : "",
          isLoading ? "animate-pulse cursor-wait" : "",
          className
        )}
      >
        {icon}
        {children}
      </Link>
    );
  }

  return (
    <button
      title={title}
      tabIndex={tabIndex}
      ref={ref}
      type={type}
      className={classNames(
        "inline-flex items-center gap-x-1.5 rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2",
        variant === "primary" && !isDisabled
          ? "bg-martEye-400 text-white focus:ring-martEye-400 hover:bg-martEye-700"
          : "",
        variant === "primary" && isDisabled
          ? "cursor-not-allowed bg-grey-300 text-white focus:ring-grey-400 hover:bg-grey-400"
          : "",
        variant === "primary-light" && !isDisabled
          ? "bg-martEye-100 text-martEye-400 focus:ring-martEye-400 hover:bg-martEye-400 hover:text-white"
          : "",
        variant === "primary-light" && isDisabled
          ? "cursor-not-allowed bg-grey-300 text-white focus:ring-grey-400 hover:bg-grey-400"
          : "",

        variant === "secondary" && !isDisabled
          ? "bg-white text-martEye-400 focus:ring-martEye-400 hover:bg-martEye-700 hover:text-white "
          : "",
        variant === "secondary" && isDisabled
          ? "cursor-not-allowed bg-grey-300 text-white focus:ring-grey-400 hover:bg-grey-400"
          : "",
        variant === "tertiary" && !isDisabled
          ? "!border-gray-300  bg-white shadow-sm focus:ring-martEye-400 hover:bg-gray-100"
          : "",
        variant === "tertiary" && isDisabled
          ? "cursor-not-allowed !border-gray-300 bg-transparent !text-gray-500 focus:ring-martEye-400 hover:bg-gray-100"
          : "",

        variant === "danger" &&
          "bg-danger-300 text-white focus:ring-danger-400 hover:bg-danger-700 hover:text-white",

        isLoading ? "animate-pulse cursor-wait" : "",
        className
      )}
      onClick={onClick ? onClick : undefined}
      disabled={isDisabled}
    >
      {icon}
      {children}
    </button>
  );
}
export function CircleButton(props: PropsWithChildren<ButtonProps>) {
  const { onClick, children, className } = props;

  return (
    <button
      type="button"
      className={classNames(
        className,
        "flex rounded-full bg-martEye-600 p-1 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-martEye-600 hover:bg-martEye-500"
      )}
      onClick={onClick ? onClick : undefined}
    >
      {children}
    </button>
  );
}

interface InterpolateBadgeProps {
  title: string;
  className?: string;
}

export function InterpolateBadge(
  props: PropsWithChildren<InterpolateBadgeProps>
) {
  const { children, title, className } = props;
  const colour = groupColours(title);
  const Color = require("color");

  const primaryColor = Color(colour);
  const colorContrast = primaryColor.isLight()
    ? Color("black")
    : Color("white");

  return (
    <span
      className={classNames(
        className,
        "inline-flex items-center rounded-full bg-grey-100 px-2.5 py-0.5 text-xs font-medium text-gray-800"
      )}
      style={{ backgroundColor: colour, color: colorContrast.string() }}
    >
      {title}
    </span>
  );
}

export function ProductCodeButton({
  title,
  marketId,
}: {
  title: string;
  marketId: string;
}) {
  const productCodes = useStudioStream("productCodes", marketId);

  // Find the product code
  const productCode = productCodes.find((code) => code.code === title);

  if (!productCode) {
    return null;
  }

  // Default colours

  let contrast = productCode?.theme?.contrast ?? null;
  let color = productCode?.theme?.color ?? null;

  if (contrast === null || color === null) {
    let defaultTheme = getProductCodeColorThemeFromList(title);

    contrast = defaultTheme.contrast;
    color = defaultTheme.color;
  }

  return (
    <span
      className={classNames(
        "inline-flex items-center rounded-full bg-grey-100 px-2.5 py-0.5 text-xs font-medium text-gray-800"
      )}
      style={{ backgroundColor: color, color: contrast }}
    >
      {title}
    </span>
  );
}

export function BackButton() {
  const router = useRouter();

  return (
    <button
      type="button"
      onClick={() => router.back()}
      className="mr-4 h-9 w-9 rounded bg-transparent text-center text-martEye-100 shadow-sm ring-1 ring-inset ring-martEye-100 hover:bg-martEye-400"
    >
      <span className="sr-only">Back</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2.5}
        stroke="currentColor"
        className="mx-auto h-5 w-5"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
        />
      </svg>
    </button>
  );
}

export function StatusButton(props: PropsWithChildren<InterpolateBadgeProps>) {
  const { title } = props;

  let className = "";

  switch (title.toLowerCase()) {
    case "closed":
    case "void":
      className = "bg-danger-300 text-danger-200";
      break;
    case "upcoming":
      className = "bg-martEye-400 text-white";
      break;
    case "live":
    case "sold":
    case "active":
      className = "bg-martEye-400 text-white";
      break;
    default:
      className = "bg-grey-100 text-gray-800";
  }

  return (
    <span
      className={classNames(
        className,
        "inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium "
      )}
    >
      {title.toUpperCase()}
    </span>
  );
}

interface LotStatusButtonProps {
  lot: string;
  onClick: () => void;
  status?: string;
}

export function LotStatusButton(
  props: PropsWithChildren<LotStatusButtonProps>
) {
  const { lot, status, onClick } = props;

  if (!lot) {
    return null;
  }

  let className = "";

  switch (status) {
    case "Error":
      className = "ring-danger-300";
      break;
    case "Incomplete":
      className = "ring-orange-400";
      break;
    default:
      className = "ring-martEye-700/20";
  }

  return (
    <button
      onClick={props.onClick}
      className={classNames(
        className,
        "inline-flex items-center rounded-md bg-white px-2 py-1 text-xs font-medium text-martEye-700 ring-2 ring-inset "
      )}
    >
      {String(lot).toUpperCase()}
    </button>
  );
}

interface InvoiceStatusButtonProps {
  status: string;
}

export function InvoiceStatusButton(
  props: PropsWithChildren<InvoiceStatusButtonProps>
) {
  const { status } = props;

  if (!status) {
    return null;
  }

  let className = "";

  switch (status) {
    case "Error":
      className = "bg-danger-300";
      break;
    case "Incomplete":
      className = "bg-orange-400";
      break;
    default:
      className = "bg-gray-100 text-gray-500";
  }

  return (
    <span
      className={classNames(
        className,
        "inline-flex items-center rounded-lg py-1 px-2 text-xs font-bold"
      )}
    >
      {toTitleCase(status)}
    </span>
  );
}

// Helper functions
function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

interface SpinnerProps {
  className?: string;
  animating?: boolean;
}

export const Spinner = (props: SpinnerProps) => {
  const className = props.className || "h-3 w-3";

  let animating = props.animating ?? true;

  if (!animating) {
    return null;
  }

  return (
    <svg
      className={`${className} animate-spin`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};
