import React from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { Media } from "types";
import { mediaVariantSelector } from "@/data/media/media.helpers";
import { MediaDisplay } from "./Media.ImageDisplay";
import { Spinner } from "../Buttons";
import "react-photo-view/dist/react-photo-view.css";

export function MediaViewerWrapper({
  children,
  media,
  mediaList,
  currentIndex = 0,
}: {
  media: Media;
  children: JSX.Element;
  mediaList?: Media[];
  currentIndex?: number;
}) {
  const selectImageVariant = mediaVariantSelector(media, "compressed");
  const largeVariant = mediaVariantSelector(media, "large");

  if (!mediaList || mediaList.length === 0) {
    return (
      <PhotoProvider maskOpacity={0.8}>
        <PhotoView src={largeVariant?.url || selectImageVariant?.url || ""}>
          {children}
        </PhotoView>
      </PhotoProvider>
    );
  }

  return (
    <PhotoProvider maskOpacity={0.8}>
      {mediaList.map((m, index) => {
        let variant =
          mediaVariantSelector(m, "large") ||
          mediaVariantSelector(m, "compressed");
        return (
          <PhotoView key={m.url} src={variant?.url || ""}>
            {currentIndex === index ? children : undefined}
          </PhotoView>
        );
      })}
    </PhotoProvider>
  );
}
