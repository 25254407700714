import SelectInput from "@/components/form/SelectInput";
import SimpleTextInput from "@/components/form/SimpleTextInput";
import TextInput from "@/components/form/TextInput";
import { classNames } from "@/data/classnames";
import { updateCustomer } from "@/data/customer";
import { useCurrentUid } from "@/data/studio-react/firebase/useFirestoreAuth";
import useStudio from "@/data/studio-react/useStudio";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { serverTimestamp } from "firebase/firestore";
import { useRef, useState } from "react";
import { toast } from "sonner";
import {
  AddressWrapper,
  Customer,
  EmailWrapper,
  PhoneNumberWrapper,
} from "../../../../types";

import { SettingsAddresses } from "./SettingsAddresses";

interface SettingsGeneralTabProps {
  customer: Customer;
  marketId: string;
}

const countryOptions = [
  { value: "GB-ENG", label: "England" },
  { value: "GB-WLS", label: "Wales" },
  { value: "GB-SCT", label: "Scotland" },
  { value: "GB-NIR", label: "Northern Ireland" },
  { value: "IE", label: "Ireland" },
];

function Card(props: { children: React.ReactNode }) {
  return (
    <div className="grid gap-4 rounded-md bg-white p-4 grid-cols-2">
      {props.children}
    </div>
  );
}

function SettingsGeneral(props: SettingsGeneralTabProps) {
  let { customer, marketId } = props;
  let currentUid = useCurrentUid();
  let customerId = customer?.id;

  return (
    <div className="grid grid-cols-2 gap-2">
      <div className="flex flex-col gap-y-2">
        <Card>
          <TextInput
            label="First Name"
            name="firstName"
            type="text"
            disabled={false}
            value={customer?.firstName || ""}
            onChange={(value) => {
              let update: Partial<Customer> = {
                firstName: value as string,
              };
              updateCustomer(marketId, currentUid, customerId, update);
            }}
          />
          <TextInput
            label="Last Name"
            name="lastName"
            type="text"
            disabled={false}
            value={customer?.lastName || ""}
            onChange={(value) => {
              let update: Partial<Customer> = {
                lastName: value as string,
              };
              updateCustomer(marketId, currentUid, customerId, update);
            }}
          />
          <TextInput
            label="Account Number"
            name="accountNumber"
            type="text"
            disabled={true}
            value={customer?.accountNumber || ""}
            onChange={(value) => {}}
          />

          <TextInput
            label="Business/Trading Name"
            name="tradingName"
            type="text"
            disabled={false}
            value={customer?.tradingName || ""}
            onChange={(value) => {
              let update: Partial<Customer> = {
                tradingName: value as string,
              };
              updateCustomer(marketId, currentUid, customerId, update);
            }}
          />
          <TextInput
            label="Vat No."
            name="vatNumber"
            type="text"
            disabled={false}
            value={customer?.vatNumber || ""}
            onChange={(value) => {
              let update: Partial<Customer> = {
                vatNumber: value as string,
              };
              updateCustomer(marketId, currentUid, customerId, update);
            }}
          />
        </Card>

        <Card>
          <SettingsAddresses customer={customer} marketId={marketId} />
        </Card>
      </div>

      <div className="flex flex-col gap-y-2">
        <BidderNumberSection customer={customer} />

        <Card>
          <TextInput
            columns={3}
            label="Phone (Primary)"
            name="phoneNumber"
            type="tel"
            disabled={false}
            value={customer?.phoneNumber?.phoneNumber || ""}
            onChange={(value) => {
              let update: Partial<Customer> = {
                phoneNumber: {
                  phoneNumber: value as string,
                  createdAt: serverTimestamp(),
                } as PhoneNumberWrapper,
              };
              updateCustomer(marketId, currentUid, customerId, update);
            }}
          />
          <TextInput
            columns={3}
            label="Email (Primary)"
            name="email"
            type="email"
            disabled={false}
            value={customer?.email?.email || ""}
            onChange={(value) => {
              let update: Partial<Customer> = {
                email: {
                  email: value as string,
                  createdAt: serverTimestamp(),
                  isVerified: false,
                } as EmailWrapper,
              };
              updateCustomer(marketId, currentUid, customerId, update);
            }}
          />
        </Card>
      </div>
    </div>
  );
}

/***
 * The user can generate a bidder number, or enter one manually.
 */
function BidderNumberSection(props: { customer: Customer }) {
  let { customer } = props;
  let { setBidderNumber, checkBidderNumberIsUnique } = useStudio();

  let attemptRef = useRef(0);

  let [loading, setLoading] = useState(false);
  let [localBidderNumber, setLocalBidderNumber] = useState<number | null>(
    customer.bidderNumber || null
  );

  let [isFocused, setIsFocused] = useState(false);
  let isDirty = localBidderNumber !== customer.bidderNumber;

  if (!loading && !isFocused && localBidderNumber !== customer.bidderNumber) {
    if (!(customer.bidderNumber === undefined && localBidderNumber === null)) {
      setLocalBidderNumber(customer.bidderNumber || null);
    }
  }

  return (
    <Card>
      <div className="relative col-span-2">
        <SimpleTextInput
          label="Bidder Number"
          name="bidderNo"
          type="text"
          disabled={loading}
          value={localBidderNumber === null ? "" : localBidderNumber.toString()}
          selectOnFocus={true}
          blurOnEnter={true}
          onFocus={() => {
            setIsFocused(true);
          }}
          onChange={(value) => {
            if (value === "") {
              setLocalBidderNumber(null);
              return;
            }

            // Only allow numberic values
            let intValue = parseInt(value as string);
            if (isNaN(intValue)) return;

            // Only allow max of 5 digits
            if (intValue > 99999) return;

            setLocalBidderNumber(intValue);
          }}
          onBlur={async (value: any) => {
            setIsFocused(false);
            if (isDirty) {
              setLoading(true);

              try {
                if (localBidderNumber !== null) {
                  if (await checkBidderNumberIsUnique(localBidderNumber!)) {
                    await setBidderNumber(customer.id, {
                      bidderNumber: localBidderNumber,
                    });
                  } else {
                    toast.error(
                      `Bidder number ${localBidderNumber} is already in use`
                    );
                    setLocalBidderNumber(customer.bidderNumber || null);
                  }
                } else {
                  await setBidderNumber(customer.id, {
                    bidderNumber: null,
                  });
                }
              } catch (e) {
                toast.error((e as Error).message);
              } finally {
                setLoading(false);
              }
            }
          }}
        />

        <button
          type="button"
          className={classNames(
            "absolute right-3 bottom-3",
            loading ? "cursor-wait" : "cursor-pointer"
          )}
          onClick={async () => {
            setLoading(true);
            try {
              let number = await setBidderNumber(customer.id, {
                attempt: attemptRef.current,
              });
              attemptRef.current++;
              setLocalBidderNumber(number);
            } catch (e) {
              toast.error((e as Error).message);
            } finally {
              setLoading(false);
            }
          }}
        >
          <ArrowPathIcon
            className={
              "h-5 w-5 text-gray-400 hover:text-green-600 hover:shadow-lg"
            }
            aria-hidden="true"
          />
        </button>
      </div>
    </Card>
  );
}

export default SettingsGeneral;
